// Color support for .navbar-arteo

#mainNav.navbar-arteo {
    border-bottom: 1px solid $gray-200;
    height: 57px;
    padding-left: 0;    
    padding-right: 0;
    
    .navbar-toggler-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
    }

    @media (max-width: 992px) {
        .topnav {
            background-color: $primary-background;

            .nav-link {
                color: white;
                padding: 10px;
            }
        }
    }

    .navbar-collapse {
        .navbar-sidenav {
            background-color: $primary-background;

            .nav-link-collapse:after {
                color: $white;
            }
            > .nav-item {
                > .nav-link {
                    color: $white;
                    padding: 10px;
                    &:hover {
                        background-color: $primary-background;
                        color: $white;
                        border-left: 2px solid $white;
                    }

                    .icon {
                        margin-right: 10px;
                    }
                }
                .sidenav-second-level,
                .sidenav-third-level {
                    > li > a {
                        color: $white;
                        &:focus,
                        &:hover {
                            background-color: $primary-background;
                            color: $white;
                        }
                    }
                }
            }
        }
        .navbar-nav > .nav-item.dropdown > .nav-link:after {
            color: $white;
        }
    }

    @media (min-width: 992px) {
        .navbar-collapse {
            .navbar-sidenav {
                background: $primary-background;
                li {
                    &.active {
                        a {
                            border-left: 2px solid $white;
                            background-color: $primary-background-second;
                        }
                    }
                }
                > .nav-item {
                    .sidenav-second-level,
                    .sidenav-third-level {
                        background: $primary-background;
                    }
                }
            }
        }
    }
}

.navbar-brand:hover {
    background: none !important;
}
