// Global styling for this template

html {
position: relative;
min-height: 100%;
}

body {
overflow-x: hidden;
background-clip: $body-background;
}

body.sticky-footer {
margin-bottom: $sticky-footer-height;
.content-wrapper {
min-height: calc(100vh - #{$sticky-footer-height} - #{$navbar-base-height});
}
}

body.fixed-nav {
padding-top: $navbar-base-height;
}

.content-wrapper {
min-height: calc(100vh - #{$sticky-footer-height});
}

main{
padding-right: 10px;
padding-left: 10px;
}

.blur-text {
    color: transparent;
    text-shadow: 0 0 8px #000;
}

// Scroll to top button
.scroll-to-top {
position: fixed;
right: 15px;
bottom: 3px;

display: none;

width: 50px;
height: 50px;

text-align: center;

color: white;
background: fade-out($gray-800, .5);

line-height: 45px;
&:focus,
&:hover {
color: white;
}
&:hover {
background: $gray-800;
}
i {
font-weight: 800;
}
}

.page-head{
padding: 10px 25px;
position: relative;
background: #fff;
border-bottom: 1px solid #ccc;
}

.page-head .page-head-action{
    text-align: right;
    margin-top: 8px;
}

.page-head h1.h2{
display: block;
margin-bottom: 5px;
margin-top: 6px;
font-weight: 300;
letter-spacing: -1px;
font-size: 32px;
color: #6b6b6b;
line-height: 36px;
}

.hide {
    display: none;
}

.dropdown-item.active {
    background-color: $primary-background-claire;
}

.pagination {
    font-size: 12px;

    .page-item.active .page-link{
        background-color: $primary-background-moins-intermediaire;
        border-color: $primary-background-moins-intermediaire;
    }
}

.navbar-brand {
    img {
        vertical-align: unset;
    }
}


