.customer-show-page {
  font-size: 13px;

  .card-title {
    font-weight: bolder;
  }

  .nav-link {
    font-size: 13px;
    margin-top: -3%;
  }

  .label {
    font-size: 10px;
  }

  .nav-link.active {
    font-weight: bold;
    border-bottom: 2px solid $primary-background-moins-claire;
  }
}