.container-dashboard {
  .btn-dashboard{
    font-size: 14px;
    margin-bottom: 20px;
    border-radius: 2px;
    padding:10px;
  }

  .btn-impaye {
    background-color: $red-color-charte;
    color: white;

    &:hover {
      background-color: $red-color-charte;
    }
  }

  .container-statistique-global {
    .fas {
      font-size: 30px;
    }

    b {
      font-size: 18px;
    }
  }
}