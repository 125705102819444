#mainNav {
	.navbar-collapse {
		overflow: auto;

		max-height: 75vh;
		.navbar-nav {
			.nav-item {
				.nav-link {
					cursor: pointer;
				}
			}
		}
		.navbar-sidenav {
      overflow-x: hidden;
      overflow-y: auto;
			.nav-link-collapse:after {
				float: right;

				@extend .fas;
				line-height: $line-height-base;
				content: fa-content($fa-var-angle-down);
			}
			.first{
				margin-top:8px;
			}
			.nav-item{
				font-size:14px;
				line-height:20px;
			}
			.icon{
				margin-right: 9px;
			}
			.nav-link-collapse.collapsed:after {
				@extend .fas;
				line-height: $line-height-base;
				content: fa-content($fa-var-angle-right);
			}
			.sidenav-second-level,
			.sidenav-third-level {
				padding-left: 0;
				> li > a {
					display: block;

					padding: 0.5em 0;
					&:focus,
					&:hover {
						text-decoration: none;
					}
				}
			}
			.sidenav-second-level > li > a {
				padding-left: 1em;
			}
			.sidenav-third-level > li > a {
				padding-left: 2em;
			}
		}
		.sidenav-toggler {
			display: none;
		}
		.navbar-nav > .nav-item.dropdown {
			> .nav-link {
				position: relative;

				min-width: 45px;
				&:after {
					float: right;

					width: auto;

					@extend .fas;
					line-height: $line-height-base;
					content: fa-content($fa-var-angle-right);

					border: none;
				}

				.indicator {
					position: absolute;
					top: 5px;
					left: 21px;
					font-size: 10px;
				}
			}
			&.show > .nav-link:after {
				@extend .fas;
				line-height: $line-height-base;
				content: fa-content($fa-var-angle-down);
			}
			.dropdown-menu > .dropdown-item > .dropdown-message {
				overflow: hidden;

				max-width: none;

				text-overflow: ellipsis;
			}
		}
	}
	@include media-breakpoint-up(lg) {
		.navbar-brand {
			width: $sidenav-base-width;
			padding: 20px;
			img{
				max-width: 100%;
			}
		}
		.navbar-collapse {
			overflow: visible;

			max-height: none;
			.navbar-sidenav {
				position: absolute;
				top: 0;
				left: 0;

				-webkit-flex-direction: column;
				-ms-flex-direction: column;
				flex-direction: column;

				margin-top: $navbar-base-height;

				> .nav-item {
					width: $sidenav-base-width;
					padding: 0;
					> .nav-link {
						padding: 0.5em 1.6em;
					}
					.sidenav-second-level,
					.sidenav-third-level {
						padding-left: 0;

						list-style: none;

						> li {
							width: $sidenav-base-width;
							> a {
								padding: 0.5em 1em;
							}
						}
					}
					.sidenav-second-level > li > a {
						padding-left: 2.75em;
					}
					.sidenav-third-level > li > a {
						padding-left: 3.75em;
					}
				}
			}
			.navbar-nav > .nav-item.dropdown {
				> .nav-link {
					min-width: 0;
					&:after {
						width: 24px;

						text-align: center;
					}
				}
				.dropdown-menu > .dropdown-item > .dropdown-message {
					max-width: 300px;
				}
			}
		}
	}
}

.nav-link{
	font-size: 15px;
	vertical-align: middle;
	color: #737373;
}

ul.navbar-nav.ml-auto .icon{
	font-size: 20px;
	vertical-align: middle;
	color: #737373;
	width: 1.28571429em;
	text-align: center;
}

ul.navbar-nav.ml-auto .icon.far.fa-arrow-alt-circle-down{
	padding-top: 4px;
}

.nav-tabs>li{
	margin-right: 20px;
	margin-bottom: 10px;
}

.nav-tabs{
	text-align:center;
}

.nav-tabs>li.active {
	position: relative;
	background: 0 0;
	color: #000;
}