.exposition-bo-container {
  font-size: 13px;

  .nav-link {
    font-size: 13px;
  }
}

.expo-menu {
  .nav-link.active  {
    background-color: transparent;
    border-bottom: 2px solid rgba(0, 0, 0, 0.125);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-radius: 0;
    color: #737373;
  }
}


.exposition-front-container {
  overflow: hidden;
  .wrapper-internal{
    padding: 20px 10px;
    margin-bottom: 8%;
    @include media-breakpoint-up(xl){
      padding: 20px 50px;
    }
  }

  .exposition-container-carousel {
    .fa {
      color:black;
      font-size: 18px;
    }

    .react-3d-carousel {
      height: 500px !important;
    }

    .exposition-front-item {
      background-color: white;
      border-radius: 20px;
    }

    .masonry-grid {
      display: flex;
    }

    .masonry-grid-column {
      margin-left: 10px;
      border: 1px solid black;
      border-radius: 5px;
      background-clip: padding-box;
      overflow: hidden;
      font-size: 12px;
    }
  }

  .exposition-presse-item {
    border-radius: 10px;
    border: 1px solid black;
    padding: 1%;
    font-size: 13px;

    .presse-item-text{
      padding-right: 3%;
    }

    p {
      text-decoration: none !important;
      text-align: justify;
    }

    .press-item-text-footer {
      font-size: 11px;
    }
  }
}

.exposition-front-footer {
  bottom: 0;
}

