.table thead tr th{
	background:$white!important;
	padding-top: 15px!important;
	padding-bottom: 10px!important;
}

.table thead tr td{
	font-size:14px;
}

.table tbody tr td.cell-detail .cell-detail-description {
    display: block;
    font-size: 14px;
    color: #999;
}
