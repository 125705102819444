/*------------------------------------------------------------------
  [Label]
*/
.label{
  border-radius: 2px;
  padding: 3px 7px;
  font-size: 11px;
  font-weight: $font-weight-bold;
  color: $light-color;
  border: transparent;
}

.label-default{
  border: 1px solid darken($light-color, 15%);
  color: $text-color;
}

.label-red {
  background-color: #A0504F;
}
.label-pink {
  @include labelColor($color-pink);
}
.label-purple {
  @include labelColor($color-purple);
}
.label-deep-purple {
  @include labelColor($color-deep-purple);
}
.label-indigo {
  @include labelColor($color-indigo);
}
.label-blue {
  background-color: #376173;
}
.label-light-blue {
  @include labelColor($color-light-blue);
}
.label-cyan {
  @include labelColor($color-cyan);
}
.label-teal {
  @include labelColor($color-teal);
}
.label-green {
  background-color: #38A089;
}
.label-light-green {
  @include labelColor($color-light-green);
}
.label-lime {
  @include labelColor($color-lime);
}
.label-yellow {
  @include labelColor($color-yellow);
}
.label-amber {
  @include labelColor($color-amber);
}
.label-orange {
  background-color: #FBAE61;
}
.label-deep-orange {
  @include labelColor($color-deep-orange);
}
.label-brown {
  @include labelColor($color-brown);
}
.label-grey {
  @include labelColor($color-grey);
}
.label-blue-grey {
  @include labelColor($color-blue-grey);
}
.label-black {
  @include labelColor($color-black);
}
.label-white {
  @include labelColor($color-white);
}