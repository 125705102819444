@mixin role($bg-color) {
	background-color: $bg-color;
}

.super_admin{
	@include role($color-red);
}

.admin{
	@include role($color-blue);
}

.user{
	@include role($color-grey);
}

.supervisor{
	@include role($color-orange);
}

.preventer{
	@include role($color-yellow);
}

.agency_director{
	@include role($color-purple);
}

.collaborator {
	@include role($color-purple);
}

.commercial {
	@include role($color-blue-grey);
}

.comptable {
	@include role($color-orange);
}

.gest_cadre {
	@include role($color-brown)
}

.photograph {
	@include role($color-yellow);
}

.stagiaire {
	@include role($color-green);
}

.assistant_comptable {
	@include role($color-grey);
}