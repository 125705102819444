.required,
.required a {
	color: #cc0000;
	font-weight: 700;
}

label.col-form-label{
	text-align: left;
	margin-bottom: 0;
	padding-top: 11px;
	color:#666;
	font-size:16px;	
	@include media-breakpoint-up(lg){
		text-align: right;
	}
}

input[type="radio"], input[type="checkbox"]{
	width:22px;
}

