.phototheque-img {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: .5s ease;
  backface-visibility: hidden;

  &:hover {
    opacity: 0.3;
  }
}

.phototheque-img-middle {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);

  &:hover {
    opacity: 0.8;
  }
}

.phototheque-img-title {
  background-color: grey;
  color: black;
  font-size: 16px;
  padding: 16px 32px;
}

/* admin photograph */
.check {
  opacity: 0.5;
  border: solid 2px red;
}
