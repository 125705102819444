@font-face {
  font-family: 'Champagne-Limousines';
  src: url('../../../fonts/champagne-limousine/champagne__limousines-webfont.woff')  format('woff');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'Champagne-Limousines-Bold';
  src: url('../../../fonts/champagne-limousine/champagne__limousines_bold-webfont.woff')  format('woff');
  font-weight: 800;
  font-style: normal;
}


