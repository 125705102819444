.container-stock {
  .container-statistique {
    .fas {
      font-size: 30px;
    }

    b {
      font-size: 18px;
    }
  }
}