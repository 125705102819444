.card-border-color{
	border-top: 3px solid #c9c9c9;
}

.card-border-color-primary{
	border-top-color: $primary-background-claire;
}

.tab-pane{

	.icon{
		display: inline-block;
		font-size: 15px;
		line-height: 25px;
		vertical-align: middle;
		cursor: pointer;
		color: #bababa;
		min-width: 20px;
		text-align: center;
	}

	h5{
		font-size:17px;
	}

}

.card-heading-divider{
	border-bottom: 1px solid #d9d9d9;
	margin: 0 20px 8px;
	padding-left: 0;
	padding-right: 0;
	font-size: 18px;
}

.card-heading-divider h5{
	font-size: 18px;
}

.card-table{
	border-color: #ddd;
	background-color: #fff;
	margin-bottom: 25px;
	box-shadow: 0 0 4px 0 rgba(0,0,0,.04);
	border-width: 0;
	border-radius: 3px;
	padding:20px 0;
}

.card-body{
	padding: 24px 20px;
	display: table;
	table-layout: fixed;
	width: 100%;

	.desc .value{
		font-size: 26px;
		font-weight: 300;
	}
}

.card-indicator{

	.desc a{
		font-size: 17px;
		line-height: 18px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		text-decoration:none;
	}

	.desc a:hover{
		text-decoration:none;
	}

	.indicator.indicator-equal{
		font-size: 29px;
		margin-right: 7px;
		line-height: 24px;
		vertical-align: middle;
	}
}

.widget.widget-tile {
	padding: 24px 20px;
	margin-bottom: 25px;
	display: table;
	table-layout: fixed;
	width: 100%;


	.chart {
		width: 85px;
		min-height: 45px;
		padding: 5px 0;
		display: table-cell;
		vertical-align: middle;
	}
	.data-info {
		display: table-cell;
		text-align: right;

		.desc{
			font-size: 17px;
			line-height: 18px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}

		.value{
			font-size: 26px;
      font-weight: 300;
    }
  }
}