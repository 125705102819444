.btn-default{
	@include button-outline-variant(#666);
	color: #404040;
	border-color: #a6a6a6!important;
	background-color: #fff;
	font-weight:700;
}

.btn-block {
	display:block;
	width:100%;

}

.btn{
	box-shadow: 0 1px 0 rgba(0,0,0,.05);
	border: 1px solid transparent;
	font-size: 13px;
	line-height: 28px;
	border-radius: 2px;
	padding:0px 10px;
	margin:5px;

	@include media-breakpoint-up(lg){
		margin:0;
	}
}

.btn-upload-file{
	padding:20px;
	max-width:100%;
}


.btn-acqui{
	font-size: 11px;
}

.btn-primary {
	background-color: $primary-background;

	&:hover {
		background-color: $primary-background-second;
		border: 0px;
	}
}

.btn-danger {
	background-color: #A0504F;
}