//Config variables
@import "includes/variables.scss";

// BigCalendar
@import "~react-big-calendar/lib/css/react-big-calendar.css";
@import "~react-big-calendar/lib/addons/dragAndDrop/styles.css";

// NPM BOOTSTRAP
@import "~bootstrap/scss/bootstrap.scss";

// NPM FONT AWESOME V5
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/brands";

//Mixins
@import "includes/mixins/background-variant.scss";
@import "includes/mixins/font.scss";
@import "includes/mixins/labels.scss";

//General style
@import "includes/general.scss";

//Structure
@import "includes/structure/navbar/navbar_global.scss";
@import "includes/structure/navbar/navbar_fixed.scss";
@import "includes/structure/navbar/navbar_static.scss";
@import "includes/structure/navbar/navbar_toggle.scss";
@import "includes/structure/navbar/navbar_colors.scss";
@import "includes/structure/footer.scss";
@import "includes/structure/btn-toolbar.scss";

//Fonts
@import "includes/fonts/roboto.scss";
@import "includes/fonts/champagne.scss";

// Elements
@import "includes/elements/buttons.scss";
@import "includes/elements/cards.scss";
@import "includes/elements/colors.scss";
@import "includes/elements/dropzone.scss";
@import "includes/elements/labels.scss";
@import "includes/elements/multiselect.scss";
@import "includes/elements/tables.scss";
@import "includes/elements/timeline-compact.scss";
@import "includes/elements/timeline.scss";
@import "includes/elements/type.scss";
@import "includes/elements/wizard.scss";

// Form
@import "includes/forms/form.scss";

// Specific pages style
@import "includes/pages/login.scss";
@import "includes/pages/404.scss";
@import "includes/pages/customer_show.scss";
@import "includes/pages/dashboard.scss";
@import "includes/pages/stock.scss";
@import "includes/pages/product.scss";

@import "includes/plugins/devbridge-autocomplete.scss";

// Helper classes
@import "includes/helpers.scss";

// Modules
@import "includes/modules/artist/art.scss";
@import "includes/modules/user/roles.scss";
@import "includes/modules/phototheque/phototheque.scss";
@import "includes/modules/platform/platform.scss";
@import "includes/modules/exposition/exposition.scss";
@import "includes/modules/note/note.scss";
@import "includes/modules/events/events.scss";
