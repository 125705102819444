.panel-heading-art {
  text-align: center;

  img {
    margin-bottom: 20px;
  }
}

.label-product{
  font-size: 16px;
}

.artistDateBirth select[name="artist[dateBirth][day]"],
.artistDateBirth select[name="artist[dateBirth][month]"] {
  display:none;
}
